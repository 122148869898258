@import './node_modules/swiper/swiper.scss';
@import './node_modules/swiper/components/navigation/navigation.scss';
@import './node_modules/swiper/components/pagination/pagination.scss';

//BANER
#banner {
  background-color: $seccolor;
  display: flex;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  aspect-ratio: 5 / 2;
  min-height: 60vh;
  position: relative;
  text-align: center;
  width: 100%;
  color: white;
  text-shadow: 1px 1px 0 #3e3e3e;
  background-position-y: 50%;
  background-size: auto 120vh;

  picture {
    position: absolute;
    inset: 0;
    z-index: 0;

    img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    z-index: 1;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-color: $headercolor;
  }

  .wrapper {
    position: relative;
    z-index: 1;
    width: 35%;
    max-width: calc(100% - 10rem);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .icon {
    background-color: white;
    border-radius: 100%;
    color: $seccolor;
    font-size: 6rem;
    width: 10rem;
    height: 10rem;
    text-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
  }

  h1 {
    font-size: 5.5rem;
    font-weight: 300;
    letter-spacing: 0.5rem;
    color: white;
  }

  .actions {
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    padding-top: 5rem;
    margin-top: 4rem;
    width: 90%;
    justify-content: center;
    gap: 3rem;

    a.button {
      color: white;
      border-color: white;
    }
  }
}

//PORTFOLIO SLIDER
.portfolio-snapshots-slider {
  clear: both;
  width: 1920px;
  max-width: 100%;
  margin: 0 auto 0;
  position: relative;
  padding: 6rem 30px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 101vw;
    height: 100%;
    z-index: -1;
    background-color: #324f6a10;
    left: 50%;
    transform: translateX(-50%);
  }

  h2 {
    text-align: center;
  }

  .button {
    width: fit-content;
    margin: 0 auto 4rem;
    padding: 1rem;
    background-color: white;
  }

  .swiper-slide {
    width: calc((100% - 100px) / 6);
    margin-right: 20px;
    box-sizing: border-box;
    background-color: white;
  }

  @media (min-width: 1280px) and (max-width: 1680px) {
    .swiper-slide {
      width: calc((100% - 80px) / 5);
    }
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    .swiper-slide {
      width: calc((100% - 60px) / 4);
    }
  }

  @media (min-width: 630px) and (max-width: 767px) {
    .swiper-slide {
      width: calc((100% - 40px) / 3);
    }
  }

  @media (min-width: 0px) and (max-width: 629px) {
    .swiper-slide {
      width: calc((100% - 10px) / 2);
      margin-right: 10px;
    }
  }

  .portfolio-single-page {
    padding-bottom: 20px;

    p {
      height: 68px;
      overflow: hidden;
    }

    img {
      max-height: 100%;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      width: auto;
      height: auto;

      &.img-loaded {
        opacity: 1;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 60%;
    margin-top: -25px;
    width: 50px;
    height: 50px;
    background-color: $maincolor;

    &:after {
      font-size: 18px;
      color: white;
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }
}

.omni-price {
  display: block;
  margin-top: 5px;
  color: #5a5a5a;
  width: 100%;
  text-align: right;
}

//GOSHOP PROMO
#goshop-promo {
  margin-block: 6rem;
  border: 3px solid $maincolor;
  background-color: $maincolor;
  outline: 3px solid white;
  outline-offset: -1rem;
  padding: 3rem;
  color: white;

  &.black-week {
    background-color: #333;
  }

  .row {
    justify-content: space-between;
    align-items: center;
  }

  .text-wrapper {
    h2 {
      margin: 0;
      text-transform: uppercase;
      font-size: 3rem;
      font-weight: bold;
      color: white;
    }

    p {
      margin: 0;
      color: white;
      font-size: 2rem;
    }

    b {
      color: white;
    }
  }

  .button {
    display: flex;
    align-items: center;
    color: white;
    background-color: transparent;
    border: 3px solid white;
    border-radius: 5px;
    width: 33%;
    text-align: center;
    justify-content: center;
    font-size: 2.2rem;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

//ONE
#one {
  margin-top: 6rem;
  margin-bottom: 6rem;

  .row-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.features {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  background-color: rgba(144, 144, 144, 0.25);

  article {
    width: calc((100% - 1px) / 2);
    background-color: white;
    padding: 3rem;
    padding-left: 12rem;
    position: relative;

    &:before {
      position: absolute;
      top: 3rem;
      left: 0;
      color: $seccolor;
      line-height: 5rem;
      font-size: 3rem;
      display: flex;
      justify-content: center;
      width: 12rem;
    }
  }

  strong {
    display: block;
    padding: 1rem 0;
    line-height: 3rem;
    font-size: 2.3rem;
    color: $seccolor;
  }

  p {
    margin: 0;
  }
}

//TWO
#two {
  color: white;
  position: relative;
  padding: 6rem 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $seccolor;
    width: 101vw;
    height: 100%;
    z-index: -1;
  }

  .row {
    gap: 1px;
    background-color: rgba(255, 255, 255, 0.25);
  }

  article {
    display: flex;
    align-items: center;
    flex: 1 1 0;
    padding: 2rem 4rem;
    background-color: $seccolor;
  }

  strong {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
    line-height: 1.6;
    color: white;
  }

  span {
    font-size: 6.8rem;
    margin-bottom: 1rem;
  }
}

//THREE
#three {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

//TESTIMONIALS SLIDER
#testimonials-slider {
  position: relative;
  padding: 6rem 0 0 0;

  &>h2 {
    text-align: center;
  }

  &>.button {
    width: fit-content;
    margin: 0 auto 4rem;
    padding: 1rem;
    background-color: white;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    background-color: #324f6a10;
    width: 101vw;
    z-index: -1;
  }

  .swiper-wrapper {
    align-items: stretch;
  }

  blockquote.swiper-slide {
    background-color: #fff;
    border-radius: 10px;
    padding: 40px 30px;
    height: auto;
    box-sizing: border-box;
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
    margin-right: 30px;
    width: calc((100% - 60px) / 3);

    figure {
      height: 90px;
      max-width: 75%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        max-height: 100%;
        max-width: 100%;
        align-self: center;
        width: auto;
        height: auto;
      }
    }

    .opinion-body {
      line-height: 2.5rem;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      display: -webkit-box !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .opinion-issuer {
      text-align: right;
      align-self: flex-end;
      margin-top: auto;

      h4 {
        margin-bottom: 5px;
      }

      a {
        color: #324f6a;
        text-decoration: underline;

        &:hover {
          color: $maincolor;
        }
      }
    }
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 30px 0;
  }

  .testimonial-prev,
  .testimonial-next {
    font-size: 28px;
    width: 40px;
    height: 40px;
    color: $maincolor;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      font-family: 'icomoon';
    }
  }

  .testimonial-prev:before {
    content: '\f053';
  }

  .testimonial-next:before {
    content: '\f054';
  }

  .testimonial-pagination {
    display: flex;
    gap: 5px;

    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
    }

    .swiper-pagination-bullet-active {
      background-color: $maincolor;
    }
  }
}