@media (max-width: 1680px) and (min-width: 1280px) {
  .inner {
    width: 1260px;
  }

  #banner {
    background-position: contain;

    .wrapper {
      width: 50%;
    }

    h1 {
      font-size: 4rem;
      margin-bottom: 0;
    }

    .actions {
      padding: 2rem 0;
      margin-top: 2rem;
    }
  }

  #one {
    gap: 0;

    .row {
      flex-wrap: nowrap;
    }
  }

  #one .row-left {
    width: auto;
    margin-left: 3rem;
  }

  .footer-demo-shop .test-wrapper {
    padding: 0 2%;
  }

  .integracja-logo {
    padding: 3rem 5rem;
  }
}
