@media (max-width: 1279px) {
  html {
    overscroll-behavior: none;
  }

  html,
  body {
    -webkit-overflow-scrolling: touch !important;
    overflow: auto !important;
    height: 100% !important;
  }

  body {
    font-size: 1.4rem;
  }

  .inner {
    width: 100%;
    padding: 0 10px;
  }

  body.privacy-box-visible {
    overflow: hidden;

    #container {
      filter: blur(5px);
      pointer-events: none;
    }
  }

  .privacy-box {
    bottom: auto;
    right: auto;
    left: 15px !important;
    right: 15px !important;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100vw;
    max-height: calc(100% - 40px);
  }

  #logo a {
    height: 32px;
    width: 67px;
  }

  #header.transparent {
    background-color: transparent;

    &:before {
      height: 2px;
    }
  }

  #header.transparent .rwd-main-nav-trigger {
    color: white;
    border-color: white;
  }

  .rwd-main-nav-trigger {
    background-color: transparent;
    text-transform: uppercase;
    border: 1px solid $bordercolor;
    border-radius: 5px;
    color: $headercolor;
    cursor: pointer;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    height: 32px;

    &:before {
      content: '\f0c9';
      font-family: icomoon;
      font-size: 1.4rem;
      margin-right: 0.5rem;
    }
  }

  #main-nav {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    pointer-events: none;

    .close-menu {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-size: 2.2rem;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;

      &:after {
        content: '\f00d';
        font-family: icomoon;
      }
    }

    .wrapper {
      background-color: #3b3b3b;
      width: 320px;
      height: 100%;
      overflow-y: auto;
      position: absolute;
      top: 0;
      right: 0;
      padding: 4.5rem 3rem;
      transition: all 0.3s ease-in-out;
      transform: translateX(100%);
      overscroll-behavior-y: contain;
    }
  }

  .main-nav-segment {
    &:last-child {
      padding-bottom: 5rem;
    }

    >*:first-child {
      font-size: 1.6rem;
      font-weight: normal;
      display: block;
      padding: 1rem 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      color: white !important;
    }

    >ul {
      a {
        color: rgba(255, 255, 255, 0.75) !important;
        font-size: 1.4rem;
        padding: 1.1rem 1.2rem;
        display: block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      }
    }

    &.try-for-free {
      >strong {
        border: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
      }

      picture {
        display: none;
      }

      br {
        display: none;
      }

      a {
        color: rgba(255, 255, 255, 0.75) !important;
        font-size: 1.3rem;
        padding: 1.1rem 1.2rem;
        display: block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      }
    }
  }

  body.show-menu {
    #main-nav {
      pointer-events: all;
    }

    #main-nav .wrapper {
      box-shadow: 0 0 1.5em 0 rgba(0, 0, 0, 0.2);
      transform: translateX(0%);
    }
  }

  #banner {
    padding: 9rem 1rem 4rem;
    background-position: bottom left;
    background-attachment: scroll;
    background-size: cover;

    &:before {
      opacity: 0.6;
    }

    .wrapper {
      width: 100%;
      max-width: 100%;
    }

    .icon {
      width: 6rem;
      height: 6rem;
      font-size: 3rem;
      margin-bottom: 2rem;
    }

    h1 {
      font-size: 4rem;
      margin-bottom: 0;
      letter-spacing: 0.3px;
    }

    .actions {
      padding-top: 2rem;
      margin-top: 2rem;
    }
  }

  #goshop-promo {
    margin-block: 3rem;
  }

  #one {
    margin: 3rem 0;
  }

  #two {
    padding: 3rem 0;

    strong {
      font-size: 1.5rem;
    }

    span {
      font-size: 4rem;
    }
  }

  #three {
    margin: 3rem 0;
  }

  main {
    padding: 8rem 0 3rem;
  }

  .footer-demo-shop .test-wrapper {
    padding: 0 2%;
  }

  h1 {
    font-size: 3.2rem;
  }

  .opinion-wrapper {
    grid-template-columns: 1fr;
  }

  #testimonials-slider blockquote.swiper-slide {
    padding: 20px;
  }

  .integracja-logo {
    padding: 4rem;
  }

  .last-ingeration-row {
    margin-bottom: -7rem;
  }
}