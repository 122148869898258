@media (max-width: 630px) {
  .portfolio-snapshots-slider {
    padding: 3rem 1rem;
    margin-top: 0;
  }

  .portfolio-snapshots-slider .button {
    margin-bottom: 2rem;
  }
}

@media (max-width: 600px) {
  #header {
    padding: 10px;
  }

  #footer {
    padding-bottom: 4.2rem;
  }

  .footer-bottom-row {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 4.2rem;
    gap: 20px;
  }

  .copyright {
    margin-left: 0;
  }

  #testimonials-slider blockquote.swiper-slide {
    margin-right: 0;
    width: 100%;
  }

  .privacy-box {
    padding: 10px;
    gap: 10px;
  }

  .privacy-box__header {
    gap: 10px;

    img {
      height: 20px;
    }

    strong {
      font-size: 14px;
    }
  }

  .privacy-box__actions {
    flex-wrap: wrap;

    button {
      min-height: 35px;
    }
  }

  .privacy-box__actions button.privacy-accept-all {
    width: 100%;
    order: -1;
    flex: 0 0 auto;
    min-height: 45px;
  }

  .consent-detail__checker input:checked+div:before {
    transform: none;
    left: 26px;
  }

  #goshop-promo .text-wrapper h2 {
    font-size: 2.3rem;
    margin-bottom: 15px;
  }

  .integracja-logo {
    grid-template-columns: repeat(auto-fit, minmax(40%, max-content));
    padding: 2rem 1rem;
  }

  .integracja-logo span {
    font-size: 2.2rem;
  }

  .integracja-tile {
    gap: 10px;
  }

  .integracja-tile strong {
    font-size: 13px;
  }

  .portfolio-snapshots-slider .portfolio-single-page p {
    height: 56px;
  }

  h1 {
    font-size: 2.8rem;
    letter-spacing: normal;
  }

  #logo span {
    display: none;
  }

  #banner .actions {
    flex-direction: column;
    gap: 1rem;

    li {
      width: 100%;
    }
  }

  #banner {
    aspect-ratio: auto;

    h1 {
      line-height: 1.4;
    }
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 1.8rem;
    letter-spacing: normal;
  }

  p {
    line-height: 2.6rem;
  }

  header h1+p {
    font-size: 1.9rem;
    margin-top: -2rem;
  }

  header h2+p {
    margin-top: 0;
  }

  .button {
    padding: 1rem;
  }

  .features article {
    width: 100%;
  }

  .tabs nav button {
    padding: 1rem;
    flex: 1 1 auto;
    color: $headercolor;
    font-size: 1.4rem;

    &.active {
      padding-top: 1.5rem;
    }
  }

  .tabs .tabs-content .row-half:nth-child(2) {
    order: -1;
  }

  .tabs .tabs-content img {
    height: 200px;
  }

  .button {
    padding: 2rem 1rem;
  }

  .portfolio-wrapper {
    gap: 10px;
  }

  .portfolio-single-page {
    width: calc((100% - 10px) / 2);
  }

  .portfolio-single-page {
    padding-bottom: 6rem;
  }

  .portfolio-single-page .img-wrapper {
    margin-bottom: 1rem;
  }

  .portfolio-single-page p b {
    font-size: 1.3rem;
  }

  .portfolio-single-page p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  .portfolio-single-page .button {
    bottom: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
  }

  blockquote {
    flex-direction: column;

    div {
      color: #5a5a5a;
      line-height: 2.6rem;
    }
  }

  blockquote .opinion-image {
    width: 60%;
    margin-bottom: 2rem;

    img {
      float: left;
    }
  }

  .promo-box .button {
    font-size: 1.6rem;
    padding: 1.6rem;
  }

  .pricing-table {
    display: block;

    tbody {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    tr {
      display: block;
      width: 100%;

      td {
        display: block;
        width: 100%;
        font-size: 1.8rem;
        line-height: normal;
        padding: 1.2rem;

        span {
          font-size: 1.4rem !important;
          margin-top: 1rem;
        }
      }
    }
  }

  .tabs .tabs-content>li {
    padding: 2rem;
  }

  .opinion-wrapper {
    gap: 30px;

    blockquote {
      gap: 15px;
      border: none;
      border-bottom: solid 1px $bordercolor;
      border-radius: 0;
      padding: 0 0 20px 0;

      figure {
        max-width: 100%;
        height: auto;
        display: block;

        img {
          display: block;
          max-height: 80px;
        }
      }

      .opinion-body {
        line-height: 2.6rem;
      }
    }

    blockquote .opinion-issuer h4 {
      margin-bottom: 0;
    }
  }

  #testimonials-slider {
    padding: 3rem 0 0 0;
  }

  #testimonials-slider blockquote.swiper-slide figure {
    max-width: 100%;
  }

  #testimonials-slider blockquote.swiper-slide {
    padding: 20px;
    gap: 10px;
  }

  #testimonials-slider blockquote.swiper-slide .opinion-issuer h4 {
    margin-bottom: 0;
  }

  #testimonials-slider>.button {
    margin-bottom: 2rem;
  }
}

@media (max-width: 420px) {
  .integracja-logo {
    grid-template-columns: repeat(auto-fit, minmax(40%, max-content)) !important;
  }
}