@media (max-width: 1100px) {

  input[type='email'],
  input[type='password'],
  input[type='text'],
  .text-input,
  select,
  textarea {
    font-size: 16px;
  }

  .call-us {
    margin: 10px 0;
  }

  #banner h1 {
    font-size: 2.2rem;
  }

  #one .row-left {
    h2 {
      text-align: center;
    }
  }

  #goshop-promo {
    text-align: center;

    .row {
      flex-direction: column;
    }

    .button {
      width: 100%;
    }
  }

  #one {
    .row-right {
      order: 2;

      .image-wrapper {
        width: 100%;
        display: block;
      }

      picture {
        width: 100%;
      }

      img {
        max-width: 100%;
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 20rem;
      }
    }
  }

  .features article {
    padding: 2rem;
    text-align: center;

    &:before {
      position: static;
      width: auto;
    }
  }

  #two {
    .row {
      flex-direction: column;
      text-align: center;
    }

    article {
      justify-content: center;
      align-items: center;
    }
  }

  .tabs .tabs-content>li {
    padding: 3rem;
  }

  #footer {
    padding: 3rem 0;
  }

  #footer .row {
    flex-direction: column;

    >* {
      width: 100%;
    }
  }

  .footer-header {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .integracja-logo {
    padding: 2rem;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(30%, max-content));
  }

  .row:not(.portfolio-wrapper) {
    flex-direction: column;

    >* {
      width: 100% !important;
    }
  }

  .svg-container-1 {
    img {
      max-height: 200px;
    }
  }

  .portfolio-single-page {
    width: calc((100% - 40px) / 3);
  }

  blockquote .opinion-image {
    width: 30%;
  }

  .price-features li {
    padding: 1.4rem 20% 1.4rem 10px;

    &:before {
      right: 0;
      width: 20%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(0, -50%);
    }
  }

  .price-values {
    justify-content: center;
    padding: 2rem;
  }

  .price-values span {
    width: 100%;
    text-align: center;
    margin: 0 0 10px;
  }

  .omni-price {
    width: 100%;
    text-align: center;
  }

  #testimonials-slider blockquote.swiper-slide {
    margin-right: 20px;
    width: calc((100% - 20px) / 2);
  }
}