.portfolio-wrapper {
  flex-wrap: wrap;
  margin-top: 2rem;
  gap: 20px;
}

.portfolio-single-page {
  display: flex;
  flex-direction: column;
  width: calc((100% - 90px) / 4);
  border: 1px solid $bordercolor;
  border-radius: 5px;
  overflow: hidden;
  align-items: center;
  padding-bottom: 8rem;
  position: relative;

  .img-wrapper {
    width: 100%;
    aspect-ratio: 1 / 0.9;
    margin-bottom: 2rem;

    img {
      display: block;
    }
  }

  p {
    padding: 0 1rem;
    width: 100%;
    text-align: center;
    font-size: 1.3rem;
    cursor: default;
    line-height: 2.1rem;
    margin-bottom: 0;

    &.rwd:after {
      content: 'sklep z wersją mobilną - sprawdź na smartfonie lub tablecie';
      line-height: 1.5rem;
      font-size: 1.3rem;
      padding: 0.4rem 0.5rem;
      color: #fff;
      border-radius: 5px;
      background-color: #f07d00cc;
      top: 0;
      right: 0;
      position: absolute;
      opacity: 0;
      width: 70%;
      text-align: left;
    }

    b {
      display: block;
      font-size: 1.7rem;
      margin-bottom: 0.5rem;
      color: #222;
    }
  }

  .button {
    flex-grow: 0;
    position: absolute;
    bottom: 2rem;
    padding: 1rem 2rem;
    font-size: 1.4rem;
  }

  @media (min-width: 1025px) {
    &:hover {
      p.rwd:after {
        opacity: 1;
      }
    }
  }
}
